import {downloadExcel} from 'react-export-table-to-excel';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
//export functions
export const ExcelExport = function exportExcel(d) {
    //conditional statement
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Sollen die aktuellen Konflikte als Excel Datei exportiert werden?")) return
    //start file checks
    const csv = d.csv
    const net = d.net
    let exprtData = []
    let exprtHeader = []
    //print to console and get common objects
    /**
     * {
     *     "kzvnumber": "056002826",
     *     "name": "Dipl.-Stom. Lorenz, Michaela",
     *     "classification": "Zahnarzt",
     *     "street": "Lukasstr.",
     *     "housenumber": "3",
     *     "zip": "01069",
     *     "city": "Dresden",
     *     "id": "81516",
     *     "address_id": "140738"
     * }
     */
    for (let i = 0; i < csv.length; i++) {
        const rawObj = {}
        const xn = net[i]
        const xc = csv[i]
        //roll-up
        for (let k of Object.keys(csv[i])) {
            //pick keys and values
            rawObj["csv_" + k] = xc[k]
            rawObj["net_" + k] = xn[k]
        }
        //push to formular
        exprtData.push(rawObj)
    }
    //write header
    for (let h of Object.keys(exprtData[0])) {
        exprtHeader.push(h.toString().toUpperCase())
    }
    console.log(exprtData)
    handleDownloadExcel(exprtHeader, exprtData)
}

const multiDataSet = [
    {
        columns: [
            { value: "Name", widthPx: 50 }, // width in pixels
            { value: "Salary", widthCh: 20 }, // width in charachters
            { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
        ],
        data: [
            ["Johnson", 30000, "Male"],
            ["Monika", 355000, "Female"],
            ["Konstantina", 20000, "Female"],
            ["John", 250000, "Male"],
            ["Josef", 450500, "Male"],
        ],
    },
    {
        xSteps: 1, // Will start putting cell with 1 empty cell on left most
        ySteps: 5, //will put space of 5 rows,
        columns: ["Name", "Department"],
        data: [
            ["Johnson", "Finance"],
            ["Monika", "IT"],
            ["Konstantina", "IT Billing"],
            ["John", "HR"],
            ["Josef", "Testing"],
        ],
    },
];

export const Download = () => {
    return (
        <ExcelFile>
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}

function handleDownloadExcel(h, d) {
    downloadExcel({
        fileName: "exported-kzv-" + d.length,
        sheet: "react-export-table-to-excel",
        tablePayload: {
            header: h,
            body: d,
        },
    });
}
