/**
 Author: Revelation.AF
 Engine: Slantapp
 Git: nusktec
 **/
import {createStyles, MenuItem, Select} from "@mui/material";
import Papa from "papaparse";
import {Settings, SwapHoriz} from '@mui/icons-material';
import {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";
import Apis from "../../Apis";
import {Download, ExcelExport} from "../../Utils";

//constants
const DTHTitle = [
    'KZV Nummer',
    'Praxis Name',
    'Straße',
    'Hausnummer',
    'PLZ',
    'Stadt',
    'Klassifizierung',
]
const DefaultTableHeadings = [
    'kzvnumber',
    'name',
    'street',
    'housenumber',
    'zip',
    'city',
    'classification',
]

const AddressImporter = () => {

    //variables
    const [getIsReady, setIsReady] = useState(false)
    const [getBtnIsReady, setBtnIsReady] = useState(false)
    const [getIsDone, setIsDone] = useState(false)
    const [getCSVHeadings, setCSVHeadings] = useState([])
    const [getOnDecision, setOnDecision] = useState({csv: [], net: []})

    // This state will store the parsed data
    const [data, setData] = useState([]);

    // correct file extension is not used
    const [error, setError] = useState("");

    // It will store the file uploaded by the user
    const [file, setFile] = useState(undefined);

    // Allowed extensions for input file
    const allowedExtensions = ["csv"];

    // This function will be called when
    // the file input changes
    const handleFileChange = (e) => {
        setError("");

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Bitte eine csv-Datei hochladen");
                return;
            }
            // If input type is correct set the state
            setFile(inputFile);
            //prepare
            setError("Überprüfe csv Datei...")
            //console.log(inputFile)
            setTimeout(async () => {
                setError("Fertig")
                setBtnIsReady(true)
            }, 1000);
        }
    };
    const handleParse = () => {
        // If user clicks the parse button without
        // a file we show a error
        if (!file) return alert("Datei ungültig, bitte erneut versuchen");

        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();

        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({target}) => {
            const csv = Papa.parse(target.result, {
                header: true,
                encoding: "UTF-8"
            });
            const parsedData = csv?.data;
            //console.table(target.result)
            const rows = Object.keys(parsedData[0]);
            //const columns = Object.values(parsedData[0]);
            // const res = rows.reduce((acc, e, i) => {
            //     return [...acc, [[e], columns[i]]];
            // }, []);
            //console.log(res)
            setCSVHeadings(rows)
            setData(parsedData);
            //show table
            setIsReady(true)
        };
        reader.readAsText(file, 'UTF-8');
    };

    return (
        (!getIsReady) ?
            <>
                <div className={'column-12'} style={Styles.divMain}>
                    <div className='column-12 boxShadow' style={Styles.divTitle}>
                        <h1 style={Styles.divTitleText}>Import Adressen mit KZV Nummern</h1>
                        <Settings style={{position: 'absolute', top: 28, right: 30, cursor: 'pointer'}}/>
                    </div>

                    <div className={'column-12 boxShadow'} style={{borderRadius: 3, overflow: 'hidden'}}>
                        <div className={'column-12'} style={Styles.divImport}>
                            <p style={{color: '#fff', paddingTop: 45}}>Bitte CSV auswählen: (Spaltentrenner müssen
                                als ';'
                                gesetzt sein)</p>
                            <h1 style={{
                                position: 'absolute',
                                right: 30,
                                top: 0,
                                color: 'rgba(255, 255, 255, 0.4)',
                                fontSize: 70,
                                fontWeight: 'bold'
                            }}>1</h1>
                        </div>
                        <div style={{background: '#fff', padding: 5}}>{(!getBtnIsReady) ?
                            <input type={'file'} accept={'text/csv'} onChange={handleFileChange}
                                   id="csvInput"
                                   name="file"/> :
                            <button className={'btn-primary'} onClick={handleParse}>Verarbeite CSV</button>
                        }
                            <p style={{padding: '0 5px', color: 'red'}}>{error}</p>
                        </div>
                    </div>
                </div>
            </> : <>
                <ImportSortTable isReady={getIsReady} csvHeads={getCSVHeadings} data={data} onDecision={(d) => {
                    setIsDone(true)
                    setOnDecision(d)
                }}/>

                {getOnDecision.csv.length > 0 ?
                    <DecisionTable data={getOnDecision} isReady={getIsReady}/> : (getIsDone) ? <>
                        <div style={{fontWeight: 'bold', fontSize: 18, textAlign: 'center', padding: 20}}>
                            Keine Dubletten oder Fehler erkannt
                        </div>
                    </> : null}
            </>
    )
}

//import table
const DecisionTable = ({data, isReady}) => {
    //variables
    const [isLoading, setIsLoading] = useState(false)
    const [focus, setFocus] = useState(0)
    const [isExport, setIsExport] = useState(false)
    const [cfData, csetFData] = useState({})
    const [nfData, nsetFData] = useState({})

    useEffect(() => {
        nsetFData(data.net[focus])
        csetFData(data.csv[focus])
    }, [data, focus])

    const updDeps = () => {
        nsetFData(data.net[focus])
        csetFData(data.csv[focus])
    }

    //next button
    const nextBtn = () => {
        if (data.net.length - 1 > focus) {
            setFocus(focus + 1)
            updDeps()
            return
        }
        toast("Letzter Eintrag");
    }

    const prevBtn = () => {
        if (focus > 0) {
            setFocus(focus - 1)
            updDeps()
            return
        }
        toast("Erster Eintrag");
    }

    //submit button
    const onAcceptBtn = async (n) => {
        setIsLoading(true)
        toast("Swapping " + n === 0 ? "CSV with user defined values. Please wait" : "System with user defined values. Please wait");
        //hit the network
        const apiCall = await (await new Apis().pushSingleUpdate(n === 0 ? cfData : nfData))
        toast(apiCall.statement);
        //remove from stack and next
        data.csv.splice(focus, 1)
        data.net.splice(focus, 1)
        //re-assign next
        updDeps()
        setIsLoading(false)
    }
    const onputNonExistentBtn = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Soll die zu importierende Praxis als non-existent importiert werden?")) return
        setIsLoading(true)
        toast("Praxis wurde als non-existent importiert");
        //hit the network
        const apiCall = await (await new Apis().pushNonExistUpdate(cfData))
        toast(apiCall.statement);
        //remove from stack and next
        data.csv.splice(focus, 1)
        data.net.splice(focus, 1)
        //re-assign next
        updDeps()
        setIsLoading(false)
    }
    //remove from array
    const onPopBtn = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Soll die Praxis aus ")) {
            toast("Praxis aus Liste entfernt!");
            //remove from stack and next
            data.csv.splice(focus, 1)
            data.net.splice(focus, 1)
            //re-assign next
            updDeps()
        }
    }

    const exportFunc = () => {
        //setIsExport(true)
        ExcelExport(data)
    }

    if (!isReady || data.length < 1) return <>
        <p style={{padding: 10}}></p>
    </>
    else return (
        <>
            <div className={'column-12 boxShadow'} style={{borderRadius: 3, overflow: 'hidden', marginTop: 20}}>
                <div className={'column-12'} style={Styles.divImport2}>
                    <p style={{color: '#fff', paddingTop: 45}}>Konfliktverwaltung</p>
                    <h1 style={{
                        position: 'absolute',
                        right: 30,
                        top: 0,
                        color: 'rgba(255, 255, 255, 0.4)',
                        fontSize: 70,
                        fontWeight: 'bold'
                    }}>3</h1>
                </div>

                {!isLoading ?
                    <>
                        <table className={'columns-12 table2'}>
                            <tbody>
                            <tr>
                                <th>Imports mit Konflikten</th>
                                <th>CSV</th>
                                <th>CRM</th>
                                <th style={{width: 500}}>Aktionen</th>
                            </tr>
                            <tr>
                                <td>{focus + 1} von {data.net.length} Konflikten<br/>
                                    <a style={{textDecoration: 'underline', color: '#fd0505'}} onClick={(e) => {
                                        e.preventDefault()
                                        exportFunc()
                                    }} href={'#'}>Export Excel</a></td>
                                <td>
                                    <input value={cfData?.kzvnumber} className={'table-input'} placeholder={"KZV"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.kzvnumber === cfData?.kzvnumber ? '#cccccc' : '#ff6b6b'
                                           }}
                                           onChange={e => csetFData({
                                               ...cfData,
                                               kzvnumber: e.target.value
                                           })}/><SwapHoriz
                                    onClick={() => {
                                        //swap data
                                        nsetFData({...nfData, kzvnumber: cfData.kzvnumber})
                                    }}/><br/>
                                    <input value={cfData?.name} className={'table-input'} placeholder={"Name"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.name === cfData?.name ? '#cccccc' : '#ff6b6b'
                                           }}
                                           onChange={e => csetFData({...cfData, name: e.target.value})}/><SwapHoriz
                                    onClick={() => {
                                        //swap data
                                        nsetFData({...nfData, name: cfData.name})
                                    }}/><br/>
                                    <input value={cfData?.street} className={'table-input'} placeholder={"Street"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.street === cfData?.street ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => csetFData({
                                        ...cfData,
                                        street: e.target.value
                                    })}/><SwapHoriz
                                    onClick={() => {
                                        //swap data
                                        nsetFData({...nfData, street: cfData.street})
                                    }}/><br/>
                                    <input value={cfData?.housenumber} className={'table-input'}
                                           placeholder={"House Number"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.housenumber === cfData?.housenumber ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => csetFData({
                                        ...cfData,
                                        housenumber: e.target.value
                                    })}/><SwapHoriz onClick={() => {
                                    //swap data
                                    nsetFData({...nfData, housenumber: cfData.housenumber})
                                }}/><br/>
                                    <input value={cfData?.zip} className={'table-input'} placeholder={"Zip"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.zip === cfData?.zip ? '#cccccc' : '#ff6b6b'
                                           }}
                                           onChange={e => csetFData({...cfData, zip: e.target.value})}/><SwapHoriz
                                    onClick={() => {
                                        //swap data
                                        //nfData.zip = cfData.kzvnumber
                                    }}/><br/>
                                    <input value={cfData?.city} className={'table-input'} placeholder={"City"}
                                           style={{
                                               marginBottom: 5,
                                               width: '95%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.city === cfData?.city ? '#cccccc' : '#ff6b6b'
                                           }}
                                           onChange={e => csetFData({...cfData, city: e.target.value})}/><SwapHoriz
                                    onClick={() => {
                                        //swap data
                                        //nfData.kzvnumber = cfData.kzvnumber
                                    }}/><br/>
                                </td>
                                <td>
                                    <input value={nfData?.kzvnumber} className={'table-input'} placeholder={"KZV"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.kzvnumber === cfData?.kzvnumber ? '#cccccc' : '#ff6b6b'
                                           }}
                                           onChange={e => nsetFData({...nfData, kzvnumber: e.target.value})}/><br/>
                                    <input value={nfData?.name || ""} className={'table-input'} placeholder={"Name"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.name === cfData?.name ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => nsetFData({...nfData, name: e.target.value})}/><br/>
                                    <input value={nfData?.street || ""} className={'table-input'}
                                           placeholder={"Street"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.street === cfData?.street ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => nsetFData({...nfData, street: e.target.value})}/><br/>
                                    <input value={nfData?.housenumber || ""} className={'table-input'}
                                           placeholder={"House Number"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.housenumber === cfData?.housenumber ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => nsetFData({
                                        ...nfData,
                                        housenumber: e.target.value
                                    })}/><br/>
                                    <input value={nfData?.zip} className={'table-input'} placeholder={"Zip"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.zip === cfData?.zip ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => nsetFData({...nfData, zip: e.target.value})}/><br/>
                                    <input value={nfData?.city || ""} className={'table-input'} placeholder={"City"}
                                           style={{
                                               marginBottom: 5,
                                               width: '100%',
                                               borderWidth: 1,
                                               borderColor: '#dcdcdc',
                                               backgroundColor: nfData?.city === cfData?.city ? '#cccccc' : '#ff6b6b'
                                           }} onChange={e => nsetFData({...nfData, city: e.target.value})}/><br/>
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <button onClick={() => onAcceptBtn(0)} className={'button'}>Übernehme CSV</button>
                                    <button onClick={onPopBtn} className={'button-normal'}>[Überspringen]</button>
                                    <button onClick={() => onAcceptBtn(1)} className={'button-danger'}>Übernehme CRM
                                    </button>
                                    <button onClick={() => onputNonExistentBtn()} className={'button-warning'}>Import CSV als
                                        [Non existent]
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{width: '100%', textAlign: 'center', margin: 10}}>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <button className={'btn-danger mr-5'} onClick={prevBtn}>{'<<Back'}</button>
                                {focus + 1} von {data.net.length}
                                <button className={'btn-primary ml-5'} onClick={nextBtn}>{'Next>>'}</button>
                            </div>
                        </div>
                    </>
                    :
                    <div style={{textAlign: 'center', height: 200, width: '100%'}}>
                        <p style={{marginTop: 100}}>Bitte warten... <img src={'/spinner.gif'} alt={''} style={{
                            width: 50,
                            height: 50,
                            margin: '0 auto'
                        }}/></p>
                    </div>
                }
            </div>
            {
                isExport ? <Download/> : null
            }
        </>
    )
}

const ImportSortTable = ({isReady, csvHeads, data, onDecision}) => {
    //variables
    const [getProgress, setProgress] = useState("")
    const [getProgressBar, setProgressBar] = useState(0)
    const [getProgressBarMax, setProgressBarMax] = useState(1)
    const [getSelectedHeads, setSelectedHeads] = useState([])
    const [getDefaultHeads, setDefaultHeads] = useState(DefaultTableHeadings)
    const [getFinalHeads, setFinaleHeads] = useState([])
    const [getNetworkResp, setGetNetworkResp] = useState([])
    const [getCSVPushedResp, setGetCSVPushedResp] = useState([])
    const [getStatement, setStatement] = useState({})
    const [getComparator, setComparator] = useState([])

    //selected items
    const putSelected = (value, pos) => {
        let tx = getSelectedHeads
        tx[pos] = (value)
        setSelectedHeads(tx)
        let ff = getDefaultHeads.filter(function (item) {
            return item !== value
        })
        setDefaultHeads(ff)
        //add to final array
        let fa = getFinalHeads
        fa[pos] = ({csv: csvHeads[pos], sql: getDefaultHeads[value], pos, posc: value})
        setFinaleHeads(fa)
        //console.log(fa)
    }

    const importBtn = () => {
        //check selections heading for processes
        setProgress("Vorbereitung...")
        if (csvHeads.length !== getSelectedHeads.length) {
            toast("Bitte alle Spalten richtig zuweisen und erneut versuchen !");
            setProgress("Bitte erneut versuchen")
            return
        }
        setProgress("Swapping csv headings")
        //remaking csv and database column
        let fixedData = []
        if (data.length < 1) {
            toast("Keine Daten in der csv gefunden, bitte erneut versuchen");
            return;
        }
        //assign progress maximum
        //setProgressBarMax(data.length + 200)
        //setProgressBar(1)
        data.slice(0,100).forEach((d, i) => {
            //check heading and swap
            let tmpObj = {}
            getFinalHeads.forEach((c, k) => {
                if (c.sql) tmpObj[c.sql] = d[c.csv];
            })
            //push to array
            fixedData.push(tmpObj)
        })
        setProgress("Bereite Uploads vor...")
        setTimeout(() => {
            pushToNetwork(fixedData).then(r => null)
        }, 1000);
        // console.log(fixedData)
        // console.log(data.slice(0, 10))
    }

    //run network push and check duplicates
    const pushToNetwork = async (cleanData) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("The system will override any previous operations, continue ?")) return
        //set progress
        setGetNetworkResp([])
        setGetCSVPushedResp([])
        setComparator([])
        setProgress("Bearbeite " + (getNetworkResp.length + 1) + " von " + cleanData.length)
        //setProgressBar(getNetworkResp.length)
        setProgressBarMax(100)
        setProgressBar(1)
        setProgress("Erwarte Aktion...")
        //push it to network batch by batch
        let i = 0;
        const callNet = async () => {
            let d = await new Apis().pushSingleAddress(cleanData[i])
            if (i <= cleanData.length) {
                callNet().then(n => null)
            } else {
                //alert
                alert("csv wurde erfolgreich eingespielt!")
                //setProgressBar(0)
                setProgress("Erwarte Aktion...")
                //pushed to decision table
                onDecision({
                    csv: getCSVPushedResp,
                    net: getNetworkResp
                })
                return
            }
            //progress
            setProgress("Verarbeite " + i + " von " + cleanData.length)
            //check if attention is needed
            if (d.status) {
                //no allow duplicate
                if (getComparator.length > 0) {
                    const isExist = getComparator.find((cd) => {
                        return (JSON.stringify(d.data) === cd)
                    })
                    if (!isExist) {
                        getNetworkResp.push(d.data.sql)
                        getCSVPushedResp.push(d.data.csv)
                    }
                    getComparator.push(JSON.stringify(d.data))
                } else {
                    getComparator.push(JSON.stringify(d.data))
                    getNetworkResp.push(d.data.sql)
                    getCSVPushedResp.push(d.data.csv)
                }
            }
            console.log(d.data)
            setStatement({
                text: d.statement ? "Zeile " + i + ": " + d.statement : '#e7e7e7',
                color: d.color ? d.color : '#ffbbbb'
            });
            setProgressBar(Math.floor(Number(i) / cleanData.length * 100))
            //next
            i++;
        }
        //call self functions
        callNet().then(n => null)
    }

    const clearBtn = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Bestätige, dass die Spaltenzuweisung zurückgesetzt wird")) {
            setSelectedHeads([])
            setDefaultHeads(DefaultTableHeadings)
            setFinaleHeads([])
            setProgressBar(0)
            setProgress(0)
            getNetworkResp.push([])
        }
    }

    if (isReady) return (
        <>
            <div className={'column-12 boxShadow'} style={{borderRadius: 3, overflow: 'hidden', marginTop: 20}}>
                <div className={'column-12'} style={Styles.divImport}>
                    <p style={{color: '#fff', paddingTop: 45}}>Bitte die gefundenen Felder per select und match zuweisen
                        und
                        mit einem Klick auf 'Zuweisung fertig' bestätigen:</p>
                    <h1 style={{
                        position: 'absolute',
                        right: 30,
                        top: 0,
                        color: 'rgba(255, 255, 255, 0.4)',
                        fontSize: 70,
                        fontWeight: 'bold'
                    }}>2</h1>
                </div>

                <div style={{overflowY: 'hidden', overflowX: 'scoll', width: '100%'}}>
                    <table className={'columns-12 table'}>
                        <tbody>
                        <tr>
                            {
                                csvHeads.map((d, i) => <th key={i}>{d}</th>)
                            }
                        </tr>
                        <tr>
                            {
                                csvHeads.map((d, i) => {
                                    return (
                                        <td key={i}>
                                            <SelectItemsComponent value={i % DefaultTableHeadings.length}
                                                                  data={getDefaultHeads}
                                                                  selected={getSelectedHeads}
                                                                  onSelect={(x, p) => putSelected(x, p)} pos={i}/>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{width: '100%', textAlign: 'center', margin: 10}}>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <button className={'btn-danger mr-5'} onClick={clearBtn}>Felder zurücksetzen</button>
                        <button className={'btn-primary ml-5'} onClick={importBtn}>Importieren</button>
                    </div>
                    <p style={{marginTop: 10}}>{getProgress}</p>
                    <p>
                        <code style={{
                            backgroundColor: getStatement?.color,
                            fontSize: 12
                        }}>Ausgabe: {getStatement?.text}</code>
                    </p>
                </div>
                <ProgressBar transitionDuration={'0s'} animateOnRender={false} borderRadius={'1'}
                             completed={getProgressBar} maxCompleted={getProgressBarMax} height={10}
                             labelSize={12} baseBgColor={'#f3f3f3'}/>
            </div>
            <ToastContainer theme={'dark'} position={'top-center'}/>
        </>
    )
}

//selections items
const SelectItemsComponent = ({data, selected, onSelect, pos}) => {
    const [getData, setData] = useState(data)
    const [getValue, setValue] = useState(data.length + 1)

    const handleChange = (event) => {
        setValue(event.target.value);
        onSelect(event.target.value, pos)
    };

    return (
        <Select
            style={{width: '100%'}}
            variant="standard"
            value={getValue}
            onChange={handleChange}>
            <MenuItem value={data.length + 1}>--Feld--auswählen--</MenuItem>
            {
                data.map((d, i) => <MenuItem disabled={selected.includes(i)} key={i} value={i}>{DTHTitle[i]}</MenuItem>)
            }
            <MenuItem style={{background: '#ffecec'}} value={data.length}>Feld ignorieren</MenuItem>
        </Select>
    )
}

//style
const Styles = createStyles({
    divMain: {
        padding: 10
    },
    divTitle: {
        height: 60,
        borderRadius: 3,
        padding: '12px 0 0 5px',
        marginBottom: 25,
        background: '#fff',
        boxShadow: 2,
    },
    divTitleText: {
        color: '#064091',
        fontSize: 20,
        fontWeight: 'bold',
    },
    divImport: {
        height: 100,
        background: '#064091',
        textAlign: 'center',
        position: 'relative'
    },
    divImport2: {
        height: 100,
        background: '#88167a',
        textAlign: 'center',
        position: 'relative'
    }
})

//export it
export default AddressImporter
