/**
 Author: Revelation.AF
 Engine: Slantapp
 Git: nusktec
 **/
import Network from "./Network";

class Apis {

  pushSingleAddress = async (data) => {
    return await (await new Network().post("/addressimporter/ins.json", data)).chopJson();
  };

  pushBulkAddresses = async (data) => {
    return await (await new Network().post("/addressimporter/bulk.json", data)).chopJson();
  };

  pushSingleUpdate = async (data) => {
    return await (await new Network().post("/addressimporter/upd.json", data)).chopJson();
  };

  pushNonExistUpdate = async (data) => {
    return await (await new Network().post("/addressimporter/inscsv.json", data)).chopJson();
  };

  //get locals
  getLocalData = () => {
    const raw = localStorage.getItem("token");
    if (!raw) {
      localStorage.clear();
      //window.location.reload();
    }
    //convert to json
    try {
      const js = JSON.parse(raw);
      if (typeof js === "object") return js;
      else localStorage.clear();
    } catch (ex) {
      console.log(ex)
      //localStorage.clear();
      //window.location.reload();
    }
  };
}

export default Apis;
