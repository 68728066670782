/**
 Author: Revelation.AF
 Engine: Slantapp
 Git: nusktec
 **/
import {createBrowserRouter} from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import * as React from "react";
//
//components
import IndexPage from "./apps/Index";
import AddressImporter from "./apps/AddressImporter";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <IndexPage/>,
        errorElement: <ErrorPage/>,
        index: true
    },
    {
        path: "/address-importer",
        element: <AddressImporter/>,
    },
]);

export default router
