/**
 Author: Revelation.AF
 Engine: Slantapp
 Git: nusktec
 **/
import * as React from "react";

function Index() {
    return(
        <>
        <div>
            <h3>Welcome to index page</h3>
        </div>
        </>
    )
}

export default Index
